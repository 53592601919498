import Image from 'next/image'
import React from 'react'

type Props = {
  onClick?: () => void
}

const CloseButton: React.FC<Props> = ({ onClick }: Props) => (
  <button type="button" onClick={onClick}>
    <div className="h-5 w-5 flex-none">
      <Image
        src="/assets/icons/ic_close.svg"
        width={20}
        height={20}
        alt="ic_close"
      />
    </div>
  </button>
)

export default CloseButton
