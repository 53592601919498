import { useRouter } from 'next/router'
import React from 'react'

import { appActions } from '@features/app/reducers'

import Button from '@components/button'

import { useAppDispatch } from '@hooks/redux'

import Modal from '../modal'

type Props = {
  open: boolean
  onClose: () => void
}

const KycNoticeModal: React.FC<Props> = ({ open, onClose }: Props) => {
  const route = useRouter()
  const dispatch = useAppDispatch()

  const onSubmit = () => {
    route.push({ pathname: '/kyc', query: { step: 'pre-kyc' } })
    dispatch(appActions.removeModal())
  }

  return (
    <Modal open={open} onClose={onClose} isCloseButtonHidden>
      <div className="w-[26rem]">
        <p className="mb-4 text-center">
          Agar aplikasi Atma tetap aman, proses verifikasi diperlukan sebelum
          Anda bisa lanjut.
        </p>
        <div className="mt-2.5 flex">
          <div className="mr-2 flex-1">
            <Button
              type="button"
              appearance="outline-primary"
              onClick={onClose}
              className="w-full"
            >
              Nanti saja
            </Button>
          </div>
          <div className="flex-1">
            <Button
              type="button"
              appearance="primary"
              onClick={onSubmit}
              className="w-full"
            >
              Verifikasi sekarang
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default KycNoticeModal
