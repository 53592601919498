import { post } from '@helpers/fetch-wrapper'
import { useFormikContext } from 'formik'
import React from 'react'

import { RedeemCodeFormValues } from '@features/coins/types'

import {
  COINS_VOUCHER_VALIDATE_FAILED,
  COINS_VOUCHER_VALIDATE_SUCCESS,
} from '@constants/event-tracking'
import { VALIDATE_VOUHER_CODE_URL } from '@constants/url'

import trackEvents from '@utils/track-events'

import { useCoinsActions } from '@store/coins/slice'

import RedeemInput from './RedeemInput'

const RedeemTabContent = () => {
  const { setFieldValue, values } = useFormikContext<RedeemCodeFormValues>()
  const coinsActions = useCoinsActions()

  const handleCodeCheck = async () => {
    try {
      const response = await post(VALIDATE_VOUHER_CODE_URL, {
        voucherCode: values.voucherCode,
      })

      const responseData: {
        id: string
        coinAmount: number
        voucherCode: string
      } = response.data

      if (responseData.id) {
        setFieldValue('isValid', 'valid')
        coinsActions.setValidateCoinAmmountState(responseData.coinAmount)
        trackEvents(COINS_VOUCHER_VALIDATE_SUCCESS, {
          VoucherCode: responseData.voucherCode,
          VoucherAmount: responseData.coinAmount,
        })
      }
    } catch (error) {
      trackEvents(COINS_VOUCHER_VALIDATE_FAILED, {
        VoucherCode: values.voucherCode,
      })
      setFieldValue('isValid', 'not-valid')
    }
  }

  return (
    <div>
      <RedeemInput
        name="voucherCode"
        label="Kode Voucher"
        placeholder="Masukkan kode"
        error={values.isValid === 'not-valid'}
        showMessage={
          values.isValid === 'not-valid' || values.isValid === 'valid'
        }
        endIcon={
          <button
            type="button"
            onClick={handleCodeCheck}
            className="text-primary-blue w-[100px]"
          >
            Cek kode
          </button>
        }
      />
    </div>
  )
}

export default RedeemTabContent
