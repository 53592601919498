import classNames from 'classnames'
import { Field, FieldProps } from 'formik'
import React, { InputHTMLAttributes } from 'react'

import TextInput from '@components/form/text-input'
import Paragraph from '@components/typography/paragraph'

type Props = {
  name: string
  label?: React.ReactNode
  placeholder: string
  suffix?: string
  isDisabled?: boolean
  type?: InputHTMLAttributes<HTMLInputElement>['type']
  inputWrapperClassName?: string
  inputContainerClassName?: string
  endIcon?: React.ReactNode
  error?: boolean
  showMessage?: boolean
} & React.InputHTMLAttributes<HTMLInputElement>

const RedeemInput = ({
  name,
  label,
  placeholder,
  suffix,
  isDisabled = false,
  type = 'text',
  inputWrapperClassName,
  inputContainerClassName,
  endIcon,
  error,
  showMessage,
  ...props
}: Props) => (
  <div>
    <Paragraph className="mb-1 text-sm font-semibold">{label}</Paragraph>
    <Field name={name}>
      {(fieldProps: FieldProps) => (
        <>
          <TextInput
            wrapperClassName={inputWrapperClassName}
            containerClassName={inputContainerClassName}
            {...fieldProps.field}
            {...props}
            placeholder={placeholder}
            type={type}
            isDisabled={isDisabled}
            error={error}
            suffix={suffix}
            endIcon={endIcon}
            style={{ textTransform: 'uppercase' }}
          />
          {showMessage && (
            <span
              className={classNames(
                'text-xs',
                error ? 'text-red-500' : 'text-[#40916C]'
              )}
            >
              {error
                ? 'Kode voucher tidak valid. Mohon periksa kembali.'
                : 'Kode voucher valid. '}
            </span>
          )}
        </>
      )}
    </Field>
  </div>
)

export default RedeemInput
