import React from 'react'

import { useAppSelector } from '@hooks/redux'

import RedeemTabContent from './RedeemTabContent'
import TopupTabContent from './TopupTabContent'

const TabContent = () => {
  const currentTab = useAppSelector((state) => state.coins.topupDrawer.tab)

  if (currentTab === 'topup') return <TopupTabContent />
  if (currentTab === 'redeem') return <RedeemTabContent />
  return null
}

export default TabContent
