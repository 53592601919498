/* eslint-disable */
import React from 'react'

const CloseIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '14'}
    height={props.height || '14'}
    viewBox="0 0 14 14"
    fill={props.fill || 'none'}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.17677 0.823221L1.17678 0.823222L6.64645 6.29285L7 6.6464L7.35355 6.29285L12.8232 0.823223C12.9209 0.725591 13.0791 0.725591 13.1768 0.823227C13.2744 0.920853 13.2744 1.07914 13.1768 1.17677L13.1767 1.17678L7.70714 6.64645L7.3536 7L7.70714 7.35355L13.1767 12.8232L13.5303 12.4697L13.1767 12.8233C13.2744 12.9209 13.2744 13.0791 13.1767 13.1767C13.0791 13.2744 12.9209 13.2744 12.8233 13.1767L12.4697 13.5303L12.8232 13.1767L7.35355 7.70714L7 7.3536L6.64645 7.70714L1.17678 13.1767L1.17677 13.1768C1.07914 13.2744 0.920853 13.2744 0.823227 13.1768C0.725591 13.0791 0.725591 12.9209 0.823227 12.8232L6.29285 7.35355L6.6464 7L6.29285 6.64645L0.823222 1.17678L0.823221 1.17677C0.725593 1.07915 0.725593 0.920849 0.823221 0.823221C0.920849 0.725593 1.07915 0.725593 1.17677 0.823221Z"
      fill="#717171"
      stroke={props.stroke || 'currentColor'}
    />
  </svg>
)

export default CloseIcon
