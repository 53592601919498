import classNames from 'classnames'
import React from 'react'

import { PaymentStatus } from '@features/coins/types'

type Props = {
  status: PaymentStatus
}

const TransactionStatusBadge = ({ status }: Props) => {
  let statusText
  let badgeClassNames = 'rounded-2xl px-2 py-1 text-xs'

  if (status === 'Success') {
    statusText = 'Sukses'
    badgeClassNames = classNames(
      badgeClassNames,
      'bg-[#D8F3DC]',
      'text-[#40916C]'
    )
  } else if (status === 'Pending') {
    statusText = 'Belum dibayar'
    badgeClassNames = classNames(
      badgeClassNames,
      'bg-[#FDF0D9]',
      'text-[#F38D15]'
    )
  } else if (status === 'Failed') {
    statusText = 'Gagal'
    badgeClassNames = classNames(
      badgeClassNames,
      'bg-[#FFE3E0]',
      'text-[#CC444B]'
    )
  } else if (status === 'Hold') {
    statusText = 'Diproses'
    badgeClassNames = classNames(
      badgeClassNames,
      'bg-border-gray',
      'text-secondary-gray'
    )
  }

  return <div className={badgeClassNames}>{statusText}</div>
}

export default TransactionStatusBadge
