import React from 'react'

const FemaleGreetSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_828_23353)">
      <rect width="64" height="64" fill="white" />
      <path
        d="M19.7713 32.0757C19.7713 32.0757 26.6799 29.2135 31.7431 36.242C32.7495 37.6535 32.5036 39.6044 31.1769 40.7203L29.5587 42.0822C28.3415 43.1052 26.5704 43.1279 25.3305 42.1297C24.1132 41.1502 22.7906 39.9267 22.4806 39.034"
        fill="white"
      />
      <path
        d="M19.7713 32.0757C19.7713 32.0757 26.6799 29.2135 31.7431 36.242C32.7495 37.6535 32.5036 39.6044 31.1769 40.7203L29.5587 42.0822C28.3415 43.1052 26.5704 43.1279 25.3305 42.1297C24.1132 41.1502 22.7906 39.9267 22.4806 39.034"
        stroke="black"
        strokeWidth="0.826639"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.7407 36.3125C31.7407 36.3125 37.2895 44.6574 41.96 43.7543C41.96 43.7543 48.414 46.9369 44.3614 52.3348C40.3088 57.7328 24.0013 40.6648 24.0013 40.6648"
        fill="white"
      />
      <path
        d="M31.7407 36.3125C31.7407 36.3125 37.2895 44.6574 41.96 43.7543C41.96 43.7543 48.414 46.9369 44.3614 52.3348C40.3088 57.7328 24.0013 40.6648 24.0013 40.6648"
        stroke="black"
        strokeWidth="0.826639"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.6293 44.8718C40.6293 44.8718 45.6966 38.8539 46.1885 31.0669C46.1885 31.0669 49.3669 30.8623 50.6875 32.9723C52.008 35.0803 49.619 46.5313 44.3616 52.3384"
        fill="white"
      />
      <path
        d="M40.6293 44.8718C40.6293 44.8718 45.6966 38.8539 46.1885 31.0669C46.1885 31.0669 49.3669 30.8623 50.6875 32.9723C52.008 35.0803 49.619 46.5313 44.3616 52.3384"
        stroke="black"
        strokeWidth="0.826639"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.678 34.8548C52.6622 34.334 53.7347 31.8954 54.5779 29.2791C54.8486 28.4421 55.1896 27.5803 55.7869 26.9211C56.4523 26.1874 57.4091 25.8857 58.1614 25.2657C58.6078 24.8979 59.3228 24.3668 58.9549 23.6993C58.5933 23.04 57.7853 23.3314 57.2603 23.5732C56.7685 23.7985 56.2994 24.0712 55.8365 24.3544C55.2227 24.7305 54.6151 25.117 53.9992 25.4931C53.8732 25.1149 53.9724 24.7016 54.0922 24.3213C54.3816 23.3955 54.7804 22.5089 55.0594 21.5789C55.2867 20.8226 55.6959 19.7066 55.4893 18.9234C55.3839 18.5328 55.0739 18.2269 54.675 18.2414C54.334 18.2538 54.0964 18.4666 53.8505 18.6733C53.7823 17.9727 53.3255 17.3259 52.5692 17.4044C51.5297 17.5139 51.3747 19.2395 51.1763 20.0186C50.8766 21.1966 50.61 22.3911 50.0934 23.4988C49.7235 24.2903 49.5602 23.1929 49.5457 22.9057C49.5147 22.296 49.5747 21.674 49.6305 21.0685C49.7193 20.091 49.9218 19.1093 49.9714 18.1298C50.0107 17.3775 49.7235 16.4517 48.7728 16.6935C47.3965 17.0427 47.6445 19.4152 47.5101 20.4692C47.3965 21.3681 47.2663 22.2671 47.1237 23.1619C47.01 23.8811 47.0079 25.0674 46.6277 25.6605C46.0676 25.3236 45.9395 24.5424 45.5779 24.0382C45.0591 23.3149 44.3441 22.4944 43.4431 22.2836C43.1889 22.2237 42.8809 22.234 42.7136 22.4345C42.635 22.5296 42.5999 22.6515 42.5751 22.7734C42.4015 23.6249 42.7012 24.466 43.1062 25.2079C43.751 26.3858 44.4144 27.4377 44.805 28.7376C45.2141 30.0995 45.3278 31.5089 45.2513 32.9246C45.2286 33.3441 45.3113 34.0695 46.047 34.4352"
        fill="white"
      />
      <path
        d="M49.678 34.8548C52.6622 34.334 53.7347 31.8954 54.5779 29.2791C54.8486 28.4421 55.1896 27.5803 55.7869 26.9211C56.4523 26.1874 57.4091 25.8857 58.1614 25.2657C58.6078 24.8979 59.3228 24.3668 58.9549 23.6993C58.5933 23.04 57.7853 23.3314 57.2603 23.5732C56.7685 23.7985 56.2994 24.0712 55.8365 24.3544C55.2227 24.7305 54.6151 25.1169 53.9992 25.4931C53.8732 25.1149 53.9724 24.7016 54.0922 24.3213C54.3816 23.3955 54.7804 22.5089 55.0594 21.5789C55.2867 20.8226 55.6959 19.7066 55.4893 18.9234C55.3839 18.5328 55.0739 18.2269 54.675 18.2414C54.334 18.2538 54.0964 18.4666 53.8505 18.6733C53.7823 17.9727 53.3255 17.3259 52.5692 17.4044C51.5297 17.5139 51.3747 19.2395 51.1763 20.0186C50.8766 21.1966 50.61 22.3911 50.0934 23.4988C49.7235 24.2903 49.5602 23.1929 49.5457 22.9057C49.5147 22.296 49.5747 21.674 49.6305 21.0685C49.7193 20.091 49.9218 19.1093 49.9714 18.1298C50.0107 17.3775 49.7235 16.4517 48.7728 16.6935C47.3965 17.0427 47.6445 19.4152 47.5101 20.4692C47.3965 21.3681 47.2663 22.2671 47.1237 23.1619C47.01 23.8811 47.0079 25.0674 46.6277 25.6605C46.0676 25.3236 45.9395 24.5424 45.5779 24.0382C45.0591 23.3149 44.3441 22.4944 43.4431 22.2836C43.1889 22.2237 42.8809 22.234 42.7136 22.4345C42.635 22.5296 42.5999 22.6515 42.5751 22.7734C42.4015 23.6249 42.7012 24.466 43.1062 25.2079C43.751 26.3858 44.4144 27.4377 44.805 28.7376C45.2141 30.0995 45.3278 31.5089 45.2513 32.9246C45.2286 33.3441 45.3113 34.0695 46.047 34.4352"
        stroke="black"
        strokeWidth="0.826639"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.6317 25.6631C46.6317 25.6631 50.0436 27.6098 49.1508 30.2881L46.6317 25.6631Z"
        fill="white"
      />
      <path
        d="M46.6317 25.6631C46.6317 25.6631 50.0436 27.6098 49.1508 30.2881"
        stroke="black"
        strokeWidth="0.826639"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.8525 18.6768C53.8525 18.6768 53.092 22.3574 52.1993 23.8267L53.8525 18.6768Z"
        fill="white"
      />
      <path
        d="M53.8525 18.6768C53.8525 18.6768 53.092 22.3574 52.1993 23.8267"
        stroke="black"
        strokeWidth="0.826639"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.50093 23.3615C7.84031 21.2681 7.12527 19.9764 6.86075 16.3351C6.59829 12.6958 8.21643 11.1996 11.7069 10.4515C12.5956 10.2696 14.41 10.2138 16.2224 9.98236C18.0328 9.70544 19.3533 9.30865 20.5706 9.11852C25.5531 8.33735 29.1469 11.6129 29.304 13.2311C29.5561 15.5332 27.7499 16.9137 27.2085 17.2733C26.5265 18.9803 30.4324 20.4125 31.9616 22.7167C33.5095 25.0499 32.9991 27.4906 28.1095 29.9065C25.3609 31.2642 21.0025 31.8057 16.892 31.723C16.2369 31.7106 13.4945 31.2435 11.7049 30.9583C11.2027 30.8984 10.717 30.8282 10.25 30.7476C10.2479 30.7476 10.2458 30.7476 10.2458 30.7476H10.2479C9.09474 30.5492 8.05937 30.2929 7.20794 29.985C2.59942 28.3193 3.15947 25.4343 5.50093 23.3615Z"
        fill="black"
      />
      <path
        d="M26.1685 11.7012C26.1685 11.7012 29.7603 13.5818 25.9784 16.1051"
        stroke="white"
        strokeWidth="0.41332"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.8445 19.7285C26.8445 19.7285 30.5127 22.0514 31.1348 24.3639"
        stroke="white"
        strokeWidth="0.41332"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.1289 25.6133C30.9925 26.0411 30.6991 26.4503 30.201 26.8284"
        stroke="white"
        strokeWidth="0.41332"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.1747 69.3238C19.4625 72.3596 28.3323 67.3212 28.3323 67.3212C28.3323 67.3212 26.7658 59.9869 31.5231 51.5675C36.2784 43.1482 28.9771 40.0628 28.9771 40.0628C29.3883 37.8618 27.5057 32.561 23.823 31.815C21.3513 31.3148 8.97654 31.1247 6.63715 35.9544C5.89731 37.4795 5.35792 40.3232 5.0562 43.0986C3.73771 55.2006 8.1747 69.3238 8.1747 69.3238Z"
        fill="white"
        stroke="black"
        strokeWidth="0.826639"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.78393 70.3005C15.2733 71.9972 21.254 70.7221 24.9532 69.352C27.6295 68.3621 29.1154 67.3205 29.1154 67.3205C29.1154 67.3205 28.1689 59.8249 32.9324 51.4077C36.9147 44.3709 30.7397 40.2584 30.7397 40.2584C29.9916 37.4747 28.9046 35.8524 27.8258 34.4326C27.8175 34.4326 26.4474 35.0257 24.1245 35.6808C20.136 36.8092 13.3493 38.1215 5.8558 36.9332C5.11802 38.4584 4.57657 40.5208 4.27485 43.2963C2.95429 55.3962 7.78393 70.3005 7.78393 70.3005Z"
        fill="#80CBC1"
      />
      <path
        d="M22.3432 44.4659C21.9939 44.3729 21.6922 44.1208 21.5517 43.7571C21.4112 43.3934 21.4649 43.0048 21.6592 42.7011C21.8245 42.4076 21.8617 42.0459 21.7315 41.707C21.498 41.1036 20.8201 40.806 20.2187 41.0374C19.6153 41.271 19.3177 41.9488 19.5492 42.5502C19.6856 42.9015 19.9708 43.1474 20.3055 43.2487C20.6383 43.35 20.9255 43.5959 21.0598 43.9472C21.1962 44.2965 21.1508 44.6726 20.971 44.9722C20.7912 45.2719 20.7457 45.646 20.8821 45.9973C21.1156 46.6007 21.7935 46.8983 22.3949 46.6669C22.9983 46.4333 23.2959 45.7555 23.0645 45.1541C22.9363 44.8131 22.6635 44.5713 22.3432 44.4659Z"
        fill="white"
      />
      <path
        d="M18.322 46.1783C18.968 46.1783 19.4917 45.6546 19.4917 45.0086C19.4917 44.3626 18.968 43.8389 18.322 43.8389C17.676 43.8389 17.1523 44.3626 17.1523 45.0086C17.1523 45.6546 17.676 46.1783 18.322 46.1783Z"
        fill="white"
      />
      <path
        d="M20.0768 28.2566C20.0768 28.2566 20.0086 31.96 20.6885 33.0925C20.6885 33.0925 16.8301 35.3327 12.0625 33.3797C12.0625 33.3797 12.2898 25.5577 11.7628 23.3691C11.7628 23.3691 17.5927 25.4502 20.0768 28.2566Z"
        fill="white"
      />
      <path
        d="M12.0624 33.3797C12.0624 33.3797 12.2897 25.5577 11.7627 23.3691C11.7627 23.3691 17.5926 25.4502 20.0787 28.2546C20.0787 28.2546 20.0105 31.9579 20.6904 33.0904"
        stroke="black"
        strokeWidth="0.826639"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.9533 69.3529C28.4418 69.2289 30.3058 67.4269 30.3058 67.4269C30.3058 67.4269 28.169 59.8259 32.9304 51.4087C36.9127 44.3719 30.7377 40.2594 30.7377 40.2594C29.9896 37.4757 30.2025 35.7128 28.1214 33.7165C28.1132 33.7165 24.2962 30.6455 21.9733 31.3007C26.4723 39.1103 28.5306 52.4151 24.9533 69.3529Z"
        fill="#53958C"
      />
      <path
        d="M20.5057 31.6767C20.5057 31.6767 24.2949 28.3887 30.133 33.2763C35.9712 38.1638 36.8805 42.8053 41.1996 43.4253L43.1133 38.9346L51.0449 42.8653C51.0449 42.8653 48.3852 53.0412 42.5305 53.9195C35.6045 54.9585 25.6765 41.191 25.6765 41.191"
        fill="#53958C"
      />
      <path
        d="M41.1992 43.4268C41.1992 43.4268 39.858 45.142 39.2277 46.7457"
        stroke="white"
        strokeWidth="0.826639"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.1136 67.2918C29.0537 66.7566 28.351 59.507 32.9327 51.41C36.915 44.3733 30.7379 40.2607 30.7379 40.2607C30.6677 40.0003 30.833 37.6217 30.7565 37.3799"
        stroke="white"
        strokeWidth="0.826639"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.66406 33.2959C9.66406 33.2959 2.22431 32.5519 -0.592464 40.7439C-1.14838 42.3869 -0.348609 44.1807 1.24474 44.8668L3.18735 45.7038C4.64843 46.332 6.35131 45.8422 7.24821 44.5279C8.13065 43.2383 9.04408 41.6863 9.08128 40.7398"
        fill="white"
      />
      <path
        d="M9.66406 33.2959C9.66406 33.2959 2.22431 32.5519 -0.592464 40.7439C-1.14838 42.3869 -0.348609 44.1807 1.24474 44.8668L3.18735 45.7038C4.64843 46.332 6.35131 45.8422 7.24821 44.5279C8.13065 43.2383 9.04408 41.6863 9.08128 40.7398"
        stroke="black"
        strokeWidth="0.826639"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.15918 44.6581C7.15918 44.6581 2.10841 53.3131 4.97478 57.1094C4.97478 57.1094 4.95825 64.3053 -1.67346 63.0447C-8.30517 61.7841 -0.156581 39.626 -0.156581 39.626"
        fill="white"
      />
      <path
        d="M7.15918 44.6581C7.15918 44.6581 2.10841 53.3131 4.97478 57.1094C4.97478 57.1094 4.95825 64.3053 -1.67346 63.0447C-8.30517 61.7841 -0.156581 39.626 -0.156581 39.626"
        stroke="black"
        strokeWidth="0.826639"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.73061 57.1094C3.73061 57.1094 13.8714 58.4506 21.9725 57.624C21.9725 57.624 23.5555 60.387 22.2432 62.5011C20.9309 64.6153 5.85714 65.2084 -1.67354 63.0426"
        fill="white"
      />
      <path
        d="M3.73061 57.1094C3.73061 57.1094 13.8714 58.4506 21.9725 57.624C21.9725 57.624 23.5555 60.387 22.2432 62.5011C20.9309 64.6153 5.85714 65.2084 -1.67354 63.0426"
        stroke="black"
        strokeWidth="0.826639"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7544 32.3766C10.7544 32.3766 16.4872 56.252 13.4823 71.1047C13.286 72.0801 12.3705 72.7414 11.3806 72.6422C9.76248 72.479 7.34662 72.1545 5.69128 71.5821C1.97554 70.2966 1.60561 68.0564 1.60561 68.0564C1.60561 68.0564 3.25269 48.058 -1.11403 39.3658C-1.11403 39.3658 0.161062 33.8646 4.77371 32.9346C9.38842 32.0005 10.7544 32.3766 10.7544 32.3766Z"
        fill="#53958C"
      />
      <path
        d="M20.2798 57.7613C20.2798 57.7613 22.9354 57.3149 23.8219 56.1659C24.7085 55.0168 29.1331 49.6706 34.8844 52.5597C34.8844 52.5597 35.9653 53.3057 35.6387 53.8864C35.0167 54.992 31.1501 52.8655 30.3834 54.4609C30.3834 54.4609 33.132 54.6097 33.6114 57.4306C34.0929 60.2515 33.1113 60.6793 32.0615 60.5595C32.0615 60.5595 31.644 61.1423 30.5611 60.6979C29.4782 60.2557 30.59 57.8274 26.6511 58.0878C26.6511 58.0878 29.5092 59.77 29.2943 61.7808C29.0752 63.833 27.2173 62.8741 26.9094 61.7808C26.37 59.8651 25.064 59.8403 24.2662 60.6587C24.2662 60.6587 24.0307 63.1861 19.3085 63.7193"
        fill="white"
      />
      <path
        d="M20.2798 57.7613C20.2798 57.7613 22.9354 57.3149 23.8219 56.1659C24.7085 55.0168 29.1331 49.6706 34.8844 52.5597C34.8844 52.5597 35.9653 53.3057 35.6387 53.8864C35.0167 54.992 31.1501 52.8655 30.3834 54.4609C30.3834 54.4609 33.132 54.6097 33.6114 57.4306C34.0929 60.2515 33.1113 60.6793 32.0615 60.5595C32.0615 60.5595 31.644 61.1423 30.5611 60.6979C29.4782 60.2557 30.59 57.8274 26.6511 58.0878C26.6511 58.0878 29.5092 59.77 29.2943 61.7808C29.0752 63.833 27.2173 62.8741 26.9094 61.7808C26.37 59.8651 25.064 59.8403 24.2662 60.6587C24.2662 60.6587 24.0307 63.1861 19.3085 63.7193"
        stroke="black"
        strokeWidth="0.826639"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.0625 60.5616C32.0625 60.5616 32.8127 55.6679 27.0737 56.1349L32.0625 60.5616Z"
        fill="white"
      />
      <path
        d="M32.0625 60.5616C32.0625 60.5616 32.8127 55.6679 27.0737 56.1349"
        stroke="black"
        strokeWidth="0.826639"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.3809 54.4614C30.3809 54.4614 29.0665 54.2217 28.2006 54.6185L30.3809 54.4614Z"
        fill="white"
      />
      <path
        d="M30.3809 54.4614C30.3809 54.4614 29.0665 54.2217 28.2006 54.6185"
        stroke="black"
        strokeWidth="0.826639"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.214624 36.3105C0.214624 36.3105 -5.14406 47.2739 -4.99734 58.8923C-4.85061 70.5128 15.927 65.2057 15.927 65.2057L18.2354 57.1563C18.2354 57.1563 9.25392 57.9086 4.974 57.1067C4.974 57.1067 6.14163 46.4451 7.15839 45.1391"
        fill="#53958C"
      />
      <path
        d="M-4.42075 51.4924C-4.79894 53.8566 -5.0304 56.3592 -4.99733 58.8929C-4.8506 70.5134 15.927 65.2063 15.927 65.2063L18.2354 57.1569C18.2354 57.1569 9.25392 57.9092 4.974 57.1073C4.974 57.1073 6.14163 46.4457 7.15839 45.1396"
        stroke="white"
        strokeWidth="0.826639"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.57843 22.1129C11.0333 25.8369 17.6134 30.2264 21.4014 28.9492C26.7705 27.1389 24.5158 20.5671 23.0609 16.8451C21.606 13.1211 17.4088 11.2839 13.6848 12.7388C9.96282 14.1916 8.12355 18.3889 9.57843 22.1129Z"
        fill="white"
      />
      <path
        d="M15.5736 27.9448C17.6113 28.9947 19.7668 29.4989 21.4014 28.9492C26.7705 27.1389 24.5158 20.5671 23.0609 16.8451C21.606 13.1211 17.4088 11.2839 13.6848 12.7388C9.96075 14.1937 8.12355 18.391 9.57843 22.115C10.0145 23.2289 10.9072 24.4027 12.048 25.4649"
        stroke="black"
        strokeWidth="0.826639"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.9551 24.5763C20.86 25.5372 20.0251 26.2585 19.0517 26.2006C18.0804 26.1427 17.3365 25.3244 17.3571 24.3613L20.9551 24.5763Z"
        fill="white"
        stroke="black"
        strokeWidth="0.61998"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.9177 22.0074C21.4613 22.0074 20.2807 20.8267 20.2807 19.3704C20.2807 17.914 21.4613 16.7334 22.9177 16.7334C24.3741 16.7334 25.5547 17.914 25.5547 19.3704C25.5547 20.8267 24.3741 22.0074 22.9177 22.0074Z"
        stroke="black"
        strokeWidth="0.61998"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9284 22.8921C14.4721 22.8921 13.2915 21.7115 13.2915 20.2551C13.2915 18.7988 14.4721 17.6182 15.9284 17.6182C17.3848 17.6182 18.5654 18.7988 18.5654 20.2551C18.5654 21.7115 17.3848 22.8921 15.9284 22.8921Z"
        stroke="black"
        strokeWidth="0.61998"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5515 19.7278C18.5515 19.7278 19.3885 19.1885 20.2812 19.4509"
        stroke="black"
        strokeWidth="0.61998"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1684 20.8224C14.9748 20.4821 15.0317 20.0846 15.2955 19.9344C15.5594 19.7842 15.9303 19.9382 16.1239 20.2785C16.3176 20.6187 16.2607 21.0163 15.9969 21.1665C15.733 21.3167 15.3621 21.1626 15.1684 20.8224Z"
        fill="black"
      />
      <path
        d="M21.27 19.964C21.0763 19.6237 21.1332 19.2262 21.3971 19.076C21.6609 18.9258 22.0318 19.0798 22.2255 19.4201C22.4192 19.7603 22.3623 20.1579 22.0984 20.3081C21.8346 20.4583 21.4637 20.3042 21.27 19.964Z"
        fill="black"
      />
      <path
        d="M21.7871 16.1055C21.7871 16.1055 20.5802 16.2377 19.9788 17.4942C19.4767 18.542 19.9623 20.9516 21.5949 23.0534"
        stroke="black"
        strokeWidth="0.61998"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.7881 11.8971C19.7881 11.8971 19.7654 17.388 8.18207 22.3768C8.18207 22.3768 7.51869 9.83459 19.7881 11.8971Z"
        fill="black"
      />
      <path
        d="M12.2373 22.2884C12.2373 22.2884 11.1069 19.6307 9.13121 21.036C7.15554 22.4413 8.69723 26 12.1898 24.9336"
        fill="white"
      />
      <path
        d="M12.2373 22.2884C12.2373 22.2884 11.1069 19.6307 9.13121 21.036C7.15554 22.4413 8.69723 26 12.1898 24.9336"
        stroke="black"
        strokeWidth="0.826639"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2664 22.9092C10.2664 22.9092 10.719 23.4961 11.7461 23.4651"
        stroke="black"
        strokeWidth="0.61998"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8165 24.0488C14.3554 24.0488 13.9816 23.8656 13.9816 23.6397C13.9816 23.4137 14.3554 23.2305 14.8165 23.2305C15.2776 23.2305 15.6514 23.4137 15.6514 23.6397C15.6514 23.8656 15.2776 24.0488 14.8165 24.0488Z"
        fill="#FC887B"
      />
      <path
        d="M22.7158 23.0143C22.6568 22.7961 22.9699 22.5217 23.415 22.4014C23.8602 22.2811 24.2689 22.3604 24.3278 22.5786C24.3868 22.7968 24.0737 23.0712 23.6286 23.1915C23.1834 23.3118 22.7747 23.2324 22.7158 23.0143Z"
        fill="#FC887B"
      />
    </g>
    <defs>
      <clipPath id="clip0_828_23353">
        <rect width="64" height="64" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default FemaleGreetSVG
