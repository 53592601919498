export enum ChatCandidateFeedbackType {
  RECRUITED = 'Recruited',
  NOT_SUITABLE = 'NotSuitable',
  SAVE_IT_FOR_LATER = 'SaveItForLater',
  DIDNT_COME_FOR_INTERVIEW = 'DidntComeForInterview',
  ITS_BEEN_REJECTED_BEFORE = 'ItsBeenRejectedBefore',
  INAPPROPRIATE_BEHAVIOR = 'InappropriateBehavior',
  OTHER = 'Other',
}
export const ChatCandidateFeedback = {
  [ChatCandidateFeedbackType.RECRUITED]: 'Direkrut',
  [ChatCandidateFeedbackType.NOT_SUITABLE]: 'Tidak cocok',
  [ChatCandidateFeedbackType.SAVE_IT_FOR_LATER]: 'Simpan untuk nanti',
  [ChatCandidateFeedbackType.DIDNT_COME_FOR_INTERVIEW]:
    'Tidak datang interview',
  [ChatCandidateFeedbackType.ITS_BEEN_REJECTED_BEFORE]: 'Sudah pernah ditolak',
  [ChatCandidateFeedbackType.INAPPROPRIATE_BEHAVIOR]: 'Perilaku tidak pantas',
  [ChatCandidateFeedbackType.OTHER]: 'Lainnya',
}
