/* eslint-disable @next/next/no-img-element */
import {
  formatMoneyFigures,
  formatNumbersWithSeparator,
} from '@helpers/formatter'
import classNames from 'classnames'
import React from 'react'
import useSWRImmutable from 'swr/immutable'

import { useAppSelector } from '@hooks/redux'
import useCoinsFeatureToggle from '@hooks/use-coins-feature-toggle'

import {
  COINS_SELECT_PACKAGE_OPTION,
  COINS_SELECT_SPECIAL_PACKAGE_OPTION,
} from '@constants/event-tracking'
import { ATMA_CS_PHONE_NUMBER } from '@constants/phone-number'
import { TOPUP_PACKAGES_URL } from '@constants/url'
import shareToWhatsApp from '@constants/whatsapp-url'

import trackEvents from '@utils/track-events'

import { BannerInfoIcon, CaretIcon } from '@common/icons'

import { useCoinsActions } from '@store/coins/slice'

import { TopupPackage } from '@custom-types/coins'

import FemaleGreetSVG from './FemaleGreetSVG'

const ButtonTopupPackage = ({
  topupPackage,
}: {
  topupPackage: TopupPackage
}) => {
  const isCurrentButtonPackageActive = useAppSelector(
    (state) => state.coins.topupDrawer.topupPackage?.id === topupPackage.id
  )

  const topupSubmitted = useAppSelector(
    (state) => state.coins.topupDrawer.isSubmitted
  )

  const coinsActions = useCoinsActions()

  return (
    <button
      type="button"
      disabled={topupSubmitted}
      className={classNames(
        'border',
        isCurrentButtonPackageActive
          ? 'border-primary-blue'
          : 'border-neutral-gray-100',
        isCurrentButtonPackageActive && 'bg-secondary-blue',
        'rounded-[6px]',
        'p-[12px]',
        'flex',
        'w-full'
      )}
      onClick={() => {
        coinsActions.setTopupDrawerState({
          topupPackage,
        })
        trackEvents(COINS_SELECT_PACKAGE_OPTION)
      }}
    >
      <p className="flex-1 text-left">
        {formatMoneyFigures(topupPackage.realAmount)}
      </p>
      <div className="flex gap-[4px]">
        <img
          src="/assets/icons/ic_coin.svg"
          alt="coin-16"
          width={16}
          height={16}
        />
        <p className="font-semibold">
          {formatNumbersWithSeparator(topupPackage.baseCoinAmount)}
        </p>
        {topupPackage.bonusCoinAmount > 0 && (
          <span className="flex items-center gap-[4px] rounded-[6px] bg-hired-0 p-[4px] text-[10px] text-green-700">
            <img
              src="/assets/icons/ic_coin.svg"
              alt="coin"
              width={12}
              height={12}
            />
            <p>+{formatNumbersWithSeparator(topupPackage.bonusCoinAmount)}</p>
          </span>
        )}
      </div>
    </button>
  )
}

const TopupTabContent = () => {
  const { isCoinsFeatureEnabled } = useCoinsFeatureToggle()

  const { data: topupPackages, isLoading } = useSWRImmutable<TopupPackage[]>(
    isCoinsFeatureEnabled ? TOPUP_PACKAGES_URL : null,
    {
      revalidateOnMount: true,
    }
  )

  if (isLoading) {
    return (
      <img
        className="mx-auto animate-spin"
        alt="animate-logo"
        src="/assets/images/img_spinner_blue.png"
        width={40}
        height={40}
      />
    )
  }

  return (
    <>
      <div className="flex items-start gap-[6px] rounded-[6px] bg-secondary-blue p-[8px]">
        <BannerInfoIcon />
        <p>
          Masa aktif Atma Coins yang dibeli adalah 1 tahun dari tanggal
          pembelian
        </p>
      </div>
      <div className="mt-[20px] flex flex-col gap-y-[20px]">
        {topupPackages?.map((tp) => (
          <ButtonTopupPackage key={tp.id} topupPackage={tp} />
        ))}
        <button
          type="button"
          className="flex items-center gap-x-[8px] overflow-hidden rounded-[6px] border"
          onClick={() => {
            trackEvents(COINS_SELECT_SPECIAL_PACKAGE_OPTION)
            window.open(shareToWhatsApp('+62', ATMA_CS_PHONE_NUMBER), '_blank')
          }}
        >
          <FemaleGreetSVG />
          <div className="flex-1 text-left">
            <p className="text-[17px] font-semibold">Perlu paket khusus?</p>
            <p className="text-primary-gray">Chat langsung dengan kami!</p>
          </div>
          <CaretIcon className="mr-[12px] rotate-[-90deg] text-primary-gray" />
        </button>
      </div>
    </>
  )
}

export default TopupTabContent
