import classNames from 'classnames'
import React from 'react'

import { useAppSelector } from '@hooks/redux'

import { COINS_VOUCHER_TAB_CLICKED } from '@constants/event-tracking'

import trackEvents from '@utils/track-events'

import { useCoinsActions } from '@store/coins/slice'

const ButtonTabs = () => {
  const currentTab = useAppSelector((state) => state.coins.topupDrawer.tab)
  const coinsActions = useCoinsActions()

  return (
    <div className="mb-[20px] flex gap-[4px]">
      <button
        type="button"
        className={classNames(
          currentTab === 'topup'
            ? 'bg-secondary-blue'
            : 'hover:bg-secondary-blue',
          'p-[12px]',
          'rounded-[6px]',
          currentTab === 'topup' && 'font-semibold',
          currentTab === 'topup' && 'text-primary-blue'
        )}
        onClick={() => {
          coinsActions.setTopupDrawerState({
            tab: 'topup',
          })
        }}
      >
        Top up
      </button>
      <button
        type="button"
        className={classNames(
          currentTab === 'redeem'
            ? 'bg-secondary-blue'
            : 'hover:bg-secondary-blue',
          'p-[12px]',
          'rounded-[6px]',
          currentTab === 'redeem' && 'font-semibold',
          currentTab === 'redeem' && 'text-primary-blue'
        )}
        onClick={() => {
          coinsActions.setTopupDrawerState({
            tab: 'redeem',
          })
          trackEvents(COINS_VOUCHER_TAB_CLICKED)
        }}
      >
        Voucher
      </button>
    </div>
  )
}

export default ButtonTabs
