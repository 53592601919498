/* eslint-disable @next/next/no-img-element */
import {
  formatMoneyFigures,
  formatNumbersWithSeparator,
} from '@helpers/formatter'
import { useFormikContext } from 'formik'
import React from 'react'
import useSWRImmutable from 'swr/immutable'

import { appActions } from '@features/app/reducers'
import { RedeemCodeFormValues } from '@features/coins/types'
import { GetEmployerProfileAPIResponseData } from '@features/kyc/types'

import Button from '@components/button'

import { useAppDispatch, useAppSelector } from '@hooks/redux'

import { KYC_STATUS } from '@constants/kyc-status'
import MODAL_NAME from '@constants/modal-name'
import { EMPLOYERS_PROFILE_URL } from '@constants/url'

import { TopupPackage } from '@custom-types/coins'

const DrawerFooter = ({
  onClickSubmitPayment,
}: {
  onClickSubmitPayment: (topupPackage: TopupPackage) => void
}) => {
  const { values } = useFormikContext<RedeemCodeFormValues>()
  const dispatch = useAppDispatch()
  const { data: employerProfileData } =
    useSWRImmutable<GetEmployerProfileAPIResponseData>(EMPLOYERS_PROFILE_URL)
  const currentTopupPackage = useAppSelector(
    (state) => state.coins.topupDrawer.topupPackage
  )
  const currentTab = useAppSelector((state) => state.coins.topupDrawer.tab)
  const isTopupSubmitted = useAppSelector(
    (state) => state.coins.topupDrawer.isSubmitted
  )
  const loadingPayment = useAppSelector(
    (state) => state.coins.topupDrawer.loadingPayment
  )
  const validateCoinAmmount = useAppSelector(
    (state) => state.coins.validateCoinAmmount
  )

  const buttonText = () => {
    if (isTopupSubmitted && currentTab === 'topup') {
      return 'Selesai'
    }

    if (!isTopupSubmitted && currentTab === 'topup') {
      return 'Bayar'
    }

    if (currentTab === 'redeem') {
      return 'Tukarkan'
    }
    return null
  }

  return (
    <div className="sticky bottom-0 !mt-auto bg-white">
      <div className="flex justify-between bg-main-gray px-[16px] py-[8px]">
        <div>
          <p className="text-[12px] font-[400] text-secondary-gray">
            Total bayar
          </p>
          <p className="font-semibold">
            {formatMoneyFigures(currentTopupPackage?.realAmount || 0)}
          </p>
        </div>
        <div>
          <p className="text-[12px] font-[400] text-secondary-gray">
            Atma Coins yang didapat
          </p>
          <div className="flex items-center gap-[4px]">
            <img
              src="/assets/icons/ic_coin.svg"
              alt="coin"
              width={16}
              height={16}
            />
            <p className="font-semibold">
              {formatNumbersWithSeparator(
                currentTab === 'topup'
                  ? (currentTopupPackage?.baseCoinAmount || 0) +
                      (currentTopupPackage?.bonusCoinAmount || 0)
                  : validateCoinAmmount || 0
              )}
            </p>
          </div>
        </div>
      </div>
      <div className="border border-t-[1px] p-[16px]">
        <Button
          block
          appearance="primary"
          disabled={
            currentTab === 'topup'
              ? !currentTopupPackage || isTopupSubmitted
              : values.isValid !== 'valid'
          }
          isLoading={loadingPayment}
          type={currentTab === 'redeem' ? 'submit' : 'button'}
          onClick={() => {
            if (currentTab === 'redeem') {
              return
            }

            if (
              employerProfileData?.kycStatus === KYC_STATUS.PENDING ||
              employerProfileData?.company?.kycStatus === KYC_STATUS.PENDING
            ) {
              dispatch(appActions.setModalName(MODAL_NAME.KYC_NOTICE_MODAL))
              return
            }

            if (
              employerProfileData?.kycStatus === KYC_STATUS.IN_REVIEW ||
              employerProfileData?.company?.kycStatus === KYC_STATUS.IN_REVIEW
            ) {
              dispatch(
                appActions.setModalName(MODAL_NAME.KYC_INREVIEW_NOTIFY_MODAL)
              )
              return
            }

            if (
              employerProfileData?.kycStatus === KYC_STATUS.REJECTED ||
              employerProfileData?.company?.kycStatus === KYC_STATUS.REJECTED
            ) {
              dispatch(appActions.setModalName(MODAL_NAME.KYC_NOTICE_MODAL))
              return
            }

            if (currentTopupPackage) {
              onClickSubmitPayment?.(currentTopupPackage)
            }
          }}
        >
          {buttonText()}
        </Button>
      </div>
    </div>
  )
}

export default DrawerFooter
