export function formatMoneyFigures(
  value: number,
  numberFormatOptions: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: 'IDR',
    maximumFractionDigits: 0,
  }
) {
  return new Intl.NumberFormat('id-ID', numberFormatOptions).format(value)
}

export function formatNumbersWithSeparator(value: number) {
  return new Intl.NumberFormat('id-ID').format(value)
}
