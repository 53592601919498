/* eslint-disable @next/next/no-img-element */

/* eslint-disable no-use-before-define */
import { formatNumbersWithSeparator } from '@helpers/formatter'
import { FormikProps } from 'formik'
import React, { RefObject } from 'react'
import useSWR from 'swr'

import useCoinsBalance from '@features/coins/hooks/useCoinsBalance'
import { RedeemCodeFormValues } from '@features/coins/types'

import { useAppSelector } from '@hooks/redux'
import useCoinsFeatureToggle from '@hooks/use-coins-feature-toggle'

import { COINS_BALANCE_URL } from '@constants/url'

import CloseIcon from '@common/icons/CloseIcon'

import { useCoinsActions } from '@store/coins/slice'

type Props = {
  formRef: RefObject<FormikProps<RedeemCodeFormValues>>
}

const DrawerTitleHeading = ({ formRef }: Props) => {
  const coinsActions = useCoinsActions()
  const isSubmitted = useAppSelector(
    (state) => state.coins.topupDrawer.isSubmitted
  )

  const { isCoinsFeatureEnabled } = useCoinsFeatureToggle()

  const { data, isLoading } = useSWR<{
    balance: number
    expiringBalance: number
  }>(isCoinsFeatureEnabled ? COINS_BALANCE_URL : null)
  const { mutateCoinsBalance } = useCoinsBalance()

  return (
    <div className="relative flex flex-col bg-primary-blue p-[16px] text-white w-full">
      <LeftBackgroundSVGItem className="absolute bottom-[-4px] left-0" />
      <RightBackgroundSVGItem className="absolute bottom-[-32px] right-0" />
      <button
        type="button"
        className="mb-[40px] w-fit self-end"
        onClick={() => {
          if (isSubmitted) {
            mutateCoinsBalance()
          }
          coinsActions.setValidateCoinAmmountState(0)
          formRef.current?.resetForm()
          coinsActions.resetTopupDrawerState()
        }}
      >
        <CloseIcon />
      </button>
      <div className="flex flex-col items-center gap-[12px] text-center">
        <p className="text-[12px] font-[400]">Atma Coins yang tersedia</p>
        <span className="flex gap-[4px]">
          {!data || isLoading ? (
            <img
              className="mx-auto animate-spin"
              alt="animate-logo"
              src="/assets/images/img_spinner_blue.png"
              width={40}
              height={40}
            />
          ) : (
            <>
              <img
                src="/assets/icons/ic_coin.svg"
                alt="coin"
                width={32}
                height={32}
              />
              <p className="text-[24px] font-[400]">
                {formatNumbersWithSeparator(data.balance)}
              </p>
            </>
          )}
        </span>
      </div>
    </div>
  )
}

const LeftBackgroundSVGItem = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="107"
    height="122"
    viewBox="0 0 107 122"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.4">
      <g opacity="0.2" clipPath="url(#clip0_828_23168)">
        <path
          d="M43.0456 65.5477C38.3711 84.2979 19.3802 95.7089 0.627764 91.0332C-18.1168 86.3587 -29.5278 67.3666 -24.8512 48.618C-20.1788 29.8657 -1.18787 18.4539 17.5587 23.1284C36.3098 27.8029 47.7201 46.797 43.0451 65.5481L43.0454 65.5477H43.0456Z"
          fill="#E4F8FF"
        />
        <path
          d="M19.6577 59.4332C18.689 59.3317 17.7582 59.0029 16.9414 58.4737C16.1245 57.9445 15.4449 57.2299 14.9579 56.3883C14.471 55.5467 14.1907 54.6021 14.1399 53.6317C14.0892 52.6613 14.2693 51.6927 14.6657 50.8051C15.1125 49.61 15.176 48.3059 14.8477 47.0732C14.5194 45.8404 13.8153 44.7398 12.8329 43.9236C11.8504 43.1073 10.6381 42.6157 9.36345 42.5166C8.08882 42.4176 6.81481 42.716 5.71752 43.3706C4.62024 44.0252 3.75386 45.0038 3.23814 46.1709C2.72243 47.3381 2.58284 48.6363 2.83867 49.886C3.09449 51.1357 3.73311 52.2753 4.66624 53.1473C5.59938 54.0192 6.78095 54.5805 8.04764 54.7534C8.97891 54.8811 9.8686 55.2195 10.6487 55.7428C11.4289 56.266 12.0788 56.9602 12.5489 57.7723C13.019 58.5845 13.2969 59.4931 13.3612 60.4287C13.4255 61.3644 13.2746 62.3023 12.92 63.1709C12.4354 64.3528 12.3309 65.6557 12.6209 66.8994C12.9109 68.1432 13.5811 69.2663 14.5388 70.1134C15.4964 70.9606 16.6943 71.4899 17.9667 71.6282C19.239 71.7666 20.523 71.507 21.6411 70.8855C22.7592 70.264 23.6561 69.3113 24.2078 68.1591C24.7595 67.0068 24.9386 65.7121 24.7206 64.4538C24.5025 63.1955 23.8979 62.0359 22.9905 61.1354C22.0831 60.2349 20.9176 59.6379 19.6553 59.4271L19.6577 59.4332Z"
          fill="#4460AC"
        />
        <path
          d="M-0.878926 71.6618C2.53024 71.6618 5.29391 68.9045 5.29391 65.5033C5.29391 62.102 2.53024 59.3447 -0.878926 59.3447C-4.28809 59.3447 -7.05176 62.102 -7.05176 65.5033C-7.05176 68.9045 -4.28809 71.6618 -0.878926 71.6618Z"
          fill="#4460AC"
        />
      </g>
      <g opacity="0.15" clipPath="url(#clip1_828_23168)">
        <path
          d="M74.493 102.847C65.028 108.534 52.7438 105.472 47.056 96.0053C41.3708 86.5425 44.434 74.2578 53.8992 68.5718C63.3643 62.8828 75.6488 65.9451 81.3348 75.4087C87.0226 84.874 83.9582 97.1593 74.4927 102.847L74.493 102.847L74.493 102.847Z"
          fill="#E4F8FF"
        />
        <path
          d="M67.5143 90.9265C67.1639 90.4941 66.9206 89.9851 66.8044 89.4412C66.6882 88.8973 66.7023 88.334 66.8456 87.7972C66.9889 87.2604 67.2573 86.7654 67.6289 86.3528C68.0005 85.9402 68.4646 85.6216 68.9835 85.4232C69.6469 85.1208 70.1995 84.6195 70.5649 83.9887C70.9304 83.358 71.0906 82.6288 71.0234 81.902C70.9563 81.1752 70.6651 80.4867 70.1901 79.9317C69.7151 79.3766 69.0797 78.9824 68.3718 78.8035C67.6639 78.6247 66.9185 78.67 66.2385 78.9332C65.5585 79.1964 64.9776 79.6646 64.576 80.2729C64.1744 80.8813 63.972 81.5998 63.9967 82.3291C64.0214 83.0585 64.2721 83.7627 64.714 84.3444C65.0387 84.7723 65.2614 85.2685 65.3652 85.7951C65.4691 86.3218 65.4512 86.8649 65.313 87.383C65.1748 87.9011 64.9199 88.3805 64.5678 88.7846C64.2157 89.1886 63.7758 89.5066 63.2816 89.7143C62.6082 89.9961 62.0395 90.4803 61.6542 91.1C61.2688 91.7197 61.0858 92.4443 61.1304 93.1736C61.1751 93.9028 61.4452 94.6007 61.9034 95.1707C62.3617 95.7407 62.9853 96.1547 63.6882 96.3553C64.3911 96.556 65.1385 96.5334 65.827 96.2908C66.5155 96.0481 67.111 95.5973 67.5313 95.0008C67.9516 94.4043 68.1759 93.6915 68.1731 92.9609C68.1703 92.2304 67.9406 91.5183 67.5157 90.923L67.5143 90.9265Z"
          fill="#4460AC"
        />
        <path
          d="M54.2752 87.5687C55.6527 88.9462 57.8835 88.9488 59.2578 87.5745C60.6321 86.2002 60.6295 83.9694 59.252 82.5919C57.8745 81.2143 55.6437 81.2118 54.2694 82.5861C52.8951 83.9604 52.8976 86.1912 54.2752 87.5687Z"
          fill="#4460AC"
        />
      </g>
      <g opacity="0.1" clipPath="url(#clip2_828_23168)">
        <path
          d="M95.7461 50.404C93.7427 58.4398 85.6038 63.3302 77.567 61.3263C69.5337 59.323 64.6432 51.1835 66.6475 43.1484C68.6499 35.1117 76.7889 30.2209 84.8232 32.2242C92.8593 34.2276 97.7494 42.3679 95.7459 50.4041L95.746 50.404H95.7461Z"
          fill="#E4F8FF"
        />
        <path
          d="M85.7236 47.7833C85.3084 47.7398 84.9095 47.5989 84.5594 47.3721C84.2093 47.1453 83.9181 46.839 83.7094 46.4784C83.5007 46.1177 83.3806 45.7128 83.3588 45.297C83.337 44.8811 83.4143 44.466 83.5841 44.0856C83.7756 43.5734 83.8028 43.0145 83.6621 42.4862C83.5214 41.9578 83.2197 41.4862 82.7986 41.1363C82.3776 40.7865 81.858 40.5758 81.3117 40.5334C80.7655 40.4909 80.2195 40.6188 79.7492 40.8994C79.2789 41.1799 78.9076 41.5993 78.6866 42.0995C78.4656 42.5997 78.4058 43.1561 78.5154 43.6917C78.625 44.2273 78.8987 44.7157 79.2986 45.0894C79.6986 45.463 80.2049 45.7036 80.7478 45.7777C81.1469 45.8324 81.5282 45.9775 81.8626 46.2017C82.1969 46.4259 82.4755 46.7234 82.6769 47.0715C82.8784 47.4196 82.9975 47.809 83.025 48.21C83.0526 48.611 82.9879 49.0129 82.836 49.3852C82.6283 49.8917 82.5835 50.4501 82.7078 50.9831C82.8321 51.5161 83.1193 51.9975 83.5297 52.3605C83.9402 52.7236 84.4535 52.9505 84.9988 53.0098C85.5441 53.069 86.0944 52.9578 86.5736 52.6915C87.0528 52.4251 87.4372 52.0168 87.6736 51.523C87.91 51.0292 87.9868 50.4743 87.8934 49.935C87.7999 49.3957 87.5408 48.8988 87.1519 48.5128C86.763 48.1269 86.2635 47.8711 85.7225 47.7807L85.7236 47.7833Z"
          fill="#4460AC"
        />
        <path
          d="M76.9219 53.0239C78.3829 53.0239 79.5674 51.8422 79.5674 50.3845C79.5674 48.9268 78.3829 47.7451 76.9219 47.7451C75.4608 47.7451 74.2764 48.9268 74.2764 50.3845C74.2764 51.8422 75.4608 53.0239 76.9219 53.0239Z"
          fill="#4460AC"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_828_23168">
        <rect
          width="70"
          height="70"
          fill="white"
          transform="translate(-25.8994 22.0811)"
        />
      </clipPath>
      <clipPath id="clip1_828_23168">
        <rect
          width="40"
          height="40"
          fill="white"
          transform="translate(64.1982 57.4258) rotate(45)"
        />
      </clipPath>
      <clipPath id="clip2_828_23168">
        <rect
          width="30"
          height="30"
          fill="white"
          transform="translate(66.1982 31.7754)"
        />
      </clipPath>
    </defs>
  </svg>
)

const RightBackgroundSVGItem = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="125"
    height="144"
    viewBox="0 0 125 144"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.4">
      <g opacity="0.2" clipPath="url(#clip0_828_23184)">
        <path
          d="M90.906 66.9656C100.859 83.5293 95.4992 105.027 78.933 114.98C62.3733 124.929 40.875 119.569 30.9246 103.005C20.9686 86.4409 26.3278 64.9429 42.8891 54.9925C59.4534 45.0389 80.9526 50.4014 90.9059 66.9662V66.9657L90.906 66.9656Z"
          fill="#E7F8F5"
        />
        <path
          d="M70.0449 79.1794C69.2882 79.7926 68.3976 80.2183 67.4457 80.4217C66.4939 80.6251 65.508 80.6004 64.5686 80.3496C63.6293 80.0988 62.7631 79.6291 62.041 78.9788C61.3189 78.3285 60.7614 77.5162 60.4141 76.6083C59.8849 75.4473 59.0077 74.4802 57.9039 73.8407C56.8 73.2012 55.524 72.9208 54.2521 73.0383C52.9802 73.1559 51.7753 73.6655 50.804 74.4967C49.8326 75.328 49.1428 76.4399 48.8298 77.6786C48.5168 78.9174 48.5961 80.222 49.0567 81.4119C49.5174 82.6019 50.3366 83.6186 51.4012 84.3214C52.4658 85.0242 53.7232 85.3784 54.9996 85.3352C56.2759 85.2919 57.5083 84.8533 58.5263 84.0799C59.2751 83.5117 60.1435 83.1218 61.0651 82.9402C61.9867 82.7585 62.9372 82.7898 63.8439 83.0317C64.7505 83.2735 65.5895 83.7195 66.2966 84.3357C67.0037 84.9518 67.5602 85.7217 67.9236 86.5866C68.4167 87.765 69.2641 88.7602 70.3486 89.4346C71.4331 90.109 72.7011 90.4292 73.9773 90.3511C75.2535 90.2729 76.4749 89.8002 77.4724 88.9983C78.4699 88.1965 79.1943 87.105 79.5454 85.8749C79.8966 84.6449 79.8571 83.337 79.4324 82.1321C79.0078 80.9273 78.2189 79.885 77.175 79.1495C76.1311 78.414 74.8837 78.0215 73.6052 78.0264C72.3268 78.0313 71.0806 78.4333 70.0389 79.1768L70.0449 79.1794Z"
          fill="#4460AC"
        />
        <path
          d="M64.17 102.348C66.5807 99.9369 66.5852 96.033 64.1802 93.628C61.7751 91.2229 57.8712 91.2274 55.4606 93.6381C53.0499 96.0487 53.0454 99.9526 55.4504 102.358C57.8555 104.763 61.7594 104.758 64.17 102.348Z"
          fill="#4460AC"
        />
      </g>
      <g opacity="0.15" clipPath="url(#clip1_828_23184)">
        <path
          d="M139.516 71.1037C136.845 81.8181 125.993 88.3387 115.278 85.6669C104.566 82.9957 98.0458 72.1431 100.718 61.4296C103.388 50.714 114.24 44.1929 124.952 46.8641C135.667 49.5352 142.187 60.389 139.516 71.1039L139.516 71.1037H139.516Z"
          fill="#E7F8F5"
        />
        <path
          d="M126.152 67.6098C125.598 67.5519 125.067 67.364 124.6 67.0616C124.133 66.7592 123.745 66.3508 123.466 65.8699C123.188 65.389 123.028 64.8492 122.999 64.2947C122.97 63.7402 123.073 63.1867 123.299 62.6795C123.555 61.9966 123.591 61.2514 123.403 60.547C123.216 59.8425 122.813 59.2136 122.252 58.7472C121.691 58.2808 120.998 57.9998 120.269 57.9432C119.541 57.8866 118.813 58.0572 118.186 58.4312C117.559 58.8053 117.064 59.3645 116.769 60.0314C116.475 60.6984 116.395 61.4402 116.541 62.1543C116.687 62.8684 117.052 63.5196 117.585 64.0179C118.119 64.5162 118.794 64.8369 119.518 64.9357C120.05 65.0087 120.558 65.202 121.004 65.501C121.45 65.8 121.821 66.1967 122.09 66.6608C122.358 67.1249 122.517 67.6441 122.554 68.1787C122.591 68.7134 122.504 69.2494 122.302 69.7457C122.025 70.421 121.965 71.1656 122.131 71.8763C122.297 72.587 122.68 73.2287 123.227 73.7128C123.774 74.1969 124.459 74.4994 125.186 74.5784C125.913 74.6575 126.646 74.5092 127.285 74.154C127.924 73.7989 128.437 73.2545 128.752 72.5961C129.067 71.9376 129.17 71.1978 129.045 70.4788C128.92 69.7597 128.575 69.0971 128.056 68.5825C127.538 68.068 126.872 67.7268 126.151 67.6064L126.152 67.6098Z"
          fill="#4460AC"
        />
        <path
          d="M114.417 74.5969C116.365 74.5969 117.944 73.0213 117.944 71.0778C117.944 69.1342 116.365 67.5586 114.417 67.5586C112.469 67.5586 110.89 69.1342 110.89 71.0778C110.89 73.0213 112.469 74.5969 114.417 74.5969Z"
          fill="#4460AC"
        />
      </g>
      <g opacity="0.1" clipPath="url(#clip2_828_23184)">
        <path
          d="M117.46 18.9928C121.726 26.0915 119.429 35.3047 112.329 39.5706C105.232 43.8344 96.0186 41.537 91.7541 34.4381C87.4873 27.3394 89.7841 18.1259 96.8817 13.8615C103.981 9.59562 113.195 11.8939 117.46 18.993V18.9928L117.46 18.9928Z"
          fill="#E7F8F5"
        />
        <path
          d="M108.52 24.2278C108.196 24.4906 107.814 24.6731 107.406 24.7602C106.998 24.8474 106.575 24.8368 106.173 24.7293C105.77 24.6219 105.399 24.4205 105.09 24.1419C104.78 23.8632 104.541 23.515 104.392 23.1259C104.166 22.6284 103.79 22.2139 103.317 21.9398C102.843 21.6658 102.297 21.5456 101.751 21.596C101.206 21.6463 100.69 21.8647 100.274 22.221C99.8574 22.5772 99.5617 23.0537 99.4276 23.5847C99.2935 24.1156 99.3274 24.6747 99.5249 25.1846C99.7223 25.6946 100.073 26.1303 100.53 26.4315C100.986 26.7327 101.525 26.8846 102.072 26.866C102.619 26.8475 103.147 26.6595 103.583 26.328C103.904 26.0845 104.276 25.9175 104.671 25.8396C105.066 25.7617 105.474 25.7751 105.862 25.8788C106.251 25.9825 106.61 26.1736 106.913 26.4377C107.216 26.7017 107.455 27.0317 107.611 27.4024C107.822 27.9074 108.185 28.3339 108.65 28.6229C109.115 28.9119 109.658 29.0492 110.205 29.0157C110.752 28.9822 111.275 28.7796 111.703 28.436C112.131 28.0923 112.441 27.6245 112.591 27.0974C112.742 26.5702 112.725 26.0097 112.543 25.4933C112.361 24.9769 112.023 24.5303 111.576 24.215C111.128 23.8998 110.594 23.7316 110.046 23.7337C109.498 23.7358 108.964 23.9081 108.517 24.2267L108.52 24.2278Z"
          fill="#4460AC"
        />
        <path
          d="M106.003 34.1569C107.036 33.1238 107.038 31.4507 106.007 30.4199C104.976 29.3892 103.303 29.3911 102.27 30.4243C101.237 31.4574 101.235 33.1305 102.266 34.1612C103.296 35.192 104.97 35.19 106.003 34.1569Z"
          fill="#4460AC"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_828_23184">
        <rect
          width="70"
          height="70"
          fill="white"
          transform="translate(11.4189 84.9814) rotate(-45)"
        />
      </clipPath>
      <clipPath id="clip1_828_23184">
        <rect
          width="40"
          height="40"
          fill="white"
          transform="translate(100.119 46.2656)"
        />
      </clipPath>
      <clipPath id="clip2_828_23184">
        <rect
          width="30"
          height="30"
          fill="white"
          transform="translate(83.3945 26.7139) rotate(-45)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default DrawerTitleHeading
