import classNames from 'classnames'
import React, { InputHTMLAttributes } from 'react'

type Props = {
  isDisabled?: boolean
  error?: boolean
  suffix?: string
  wrapperClassName?: string
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  containerClassName?: string
} & InputHTMLAttributes<HTMLInputElement>

const TextInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      isDisabled = false,
      error,
      suffix,
      wrapperClassName = '',
      containerClassName = '',
      startIcon = null,
      endIcon = null,
      ...props
    },
    ref
  ) => (
    <div className={`relative flex items-center ${wrapperClassName}`}>
      <div
        className={classNames(
          'border',
          'rounded-md',
          'w-full',
          'py-[.625rem]',
          'px-[0.5rem]',
          'border-border-gray',
          error ? 'border-red-500' : '',
          'focus-within:border-border-focused-gray',
          'flex',
          'gap-x-2',
          containerClassName,
          isDisabled && 'bg-gray-200'
        )}
      >
        {startIcon}
        <input
          {...props}
          ref={ref}
          disabled={isDisabled}
          autoComplete="off"
          className={classNames(
            'w-full border-none p-0 outline-none focus:border-none focus:outline-none',
            isDisabled
              ? 'cursor-not-allowed bg-gray-200 text-primary-gray'
              : 'text-primary-black'
          )}
        />
        {endIcon}
      </div>
      {suffix && <span className="absolute right-4">{suffix}</span>}
    </div>
  )
)
TextInput.displayName = 'TextInput'

export default TextInput
