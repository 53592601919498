/* eslint-disable */
import { post } from '@helpers/fetch-wrapper'
import { Form, Formik, FormikProps } from 'formik'
import React, { useRef, useState } from 'react'
import { toast } from 'react-toastify'
import useSWRImmutable from 'swr/immutable'

import { appActions } from '@features/app/reducers'
import useCoinsBalance from '@features/coins/hooks/useCoinsBalance'
import {
  RedeemCodeAPIResponse,
  RedeemCodeFormValues,
} from '@features/coins/types'
import { GetEmployerProfileAPIResponseData } from '@features/kyc/types'

import Drawer from '@components/drawer/Drawer'
import KycNoticeModal from '@components/modal/kyc-notice-modal'

import { useAppDispatch, useAppSelector } from '@hooks/redux'

import { COINS_VOUCHER_REDEMPTION_SUCCESS } from '@constants/event-tracking'
import { KYC_STATUS } from '@constants/kyc-status'
import MODAL_NAME from '@constants/modal-name'
import { EMPLOYERS_PROFILE_URL, REDEEM_VOUCHER_CODE_URL } from '@constants/url'

import errorFormat from '@utils/error-format'
import trackEvents from '@utils/track-events'

import { useCoinsActions } from '@store/coins/slice'

import { TopupPackage } from '@custom-types/coins'

import RedeemVoucherModal from '../redeem-voucher-modal'
import ButtonTabs from './ButtonTabs'
import DrawerFooter from './DrawerFooter'
import DrawerTitleHeading from './DrawerTitleHeading'
import TabContent from './TabContent'

const CoinsTopupDrawer = ({
  onSubmitPayment,
}: {
  onSubmitPayment: (topupPackage: TopupPackage) => void
}) => {
  const dispatch = useAppDispatch()
  const [isVoucherModalOpen, setIsVoucherModalOpen] = useState<boolean>(false)
  const { modalName } = useAppSelector((state) => state.appReducer)
  const openDrawer = useAppSelector((state) => state.coins.topupDrawer.open)
  const isSubmitted = useAppSelector(
    (state) => state.coins.topupDrawer.isSubmitted
  )
  const { data: employerProfileData } =
    useSWRImmutable<GetEmployerProfileAPIResponseData>(EMPLOYERS_PROFILE_URL)
  const coinsActions = useCoinsActions()
  const currentTab = useAppSelector((state) => state.coins.topupDrawer.tab)
  const formRef = useRef<FormikProps<RedeemCodeFormValues>>(null)

  const { mutateCoinsBalance } = useCoinsBalance()

  const initialValues: RedeemCodeFormValues = {
    voucherCode: '',
    isValid: 'unchecked',
  }

  const onSubmit = async (values: RedeemCodeFormValues, action: any) => {
    if (currentTab === 'topup') {
      return
    }

    if (
      employerProfileData?.kycStatus === KYC_STATUS.PENDING ||
      employerProfileData?.company?.kycStatus === KYC_STATUS.PENDING
    ) {
      dispatch(appActions.setModalName(MODAL_NAME.KYC_NOTICE_MODAL))
      return
    }

    if (
      employerProfileData?.kycStatus === KYC_STATUS.IN_REVIEW ||
      employerProfileData?.company?.kycStatus === KYC_STATUS.IN_REVIEW
    ) {
      dispatch(appActions.setModalName(MODAL_NAME.KYC_INREVIEW_NOTIFY_MODAL))
      return
    }

    if (
      employerProfileData?.kycStatus === KYC_STATUS.REJECTED ||
      employerProfileData?.company?.kycStatus === KYC_STATUS.REJECTED
    ) {
      dispatch(appActions.setModalName(MODAL_NAME.KYC_NOTICE_MODAL))
      return
    }

    try {
      const response = await post(REDEEM_VOUCHER_CODE_URL, {
        voucherCode: values.voucherCode,
      })

      const responseData: RedeemCodeAPIResponse = response.data

      if (responseData.id) {
        setIsVoucherModalOpen(true)
        coinsActions.setRedeemCodeDetailState(responseData)
        coinsActions.setValidateCoinAmmountState(0)
        mutateCoinsBalance()
        action.resetForm()
      }
      trackEvents(COINS_VOUCHER_REDEMPTION_SUCCESS, {
        VoucherCode: values.voucherCode,
        VoucherAmount: responseData?.amount,
      })
    } catch (error) {
      toast.error(errorFormat(error))
    }
  }

  return (
    <>
      <Drawer
        isOpen={openDrawer}
        onClose={() => {
          if (isSubmitted) {
            mutateCoinsBalance()
          }
          coinsActions.setValidateCoinAmmountState(0)
          formRef.current?.resetForm()
          coinsActions.resetTopupDrawerState()
        }}
        titleContainerClassName="w-full mr-[0]"
        title={<DrawerTitleHeading formRef={formRef} />}
        hideCloseButton
        articleClassName="!p-0"
      >
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          innerRef={formRef}
        >
          <Form className="flex flex-col h-full">
            <div className="px-[20px] pb-[20px] pt-[5px]">
              <ButtonTabs />
              <TabContent />
            </div>
            <DrawerFooter onClickSubmitPayment={onSubmitPayment} />
          </Form>
        </Formik>
      </Drawer>
      <KycNoticeModal
        open={modalName === MODAL_NAME.KYC_NOTICE_MODAL}
        onClose={() => dispatch(appActions.removeModal())}
      />
      <RedeemVoucherModal
        open={isVoucherModalOpen}
        onClose={() => {
          setIsVoucherModalOpen(false)
        }}
      />
    </>
  )
}

export default CoinsTopupDrawer
