/* eslint-disable no-use-before-define */
import useSWR from 'swr'

import useCoinsFeatureToggle from '@hooks/use-coins-feature-toggle'

import { PRICE_LIST_URL } from '@constants/url'

import { GetPriceListAPIResponse } from '../types'

export const usePriceList = () => {
  const { isCoinsFeatureEnabled } = useCoinsFeatureToggle()

  const { data, mutate, isLoading } = useSWR<GetPriceListAPIResponse[]>(
    isCoinsFeatureEnabled ? PRICE_LIST_URL : null
  )

  return {
    priceListData: data,
    mutatePriceList: mutate,
    priceListIsLoading: isLoading,
  }
}

export default usePriceList
