import classNames from 'classnames'
import React, { ReactNode } from 'react'

type Props = {
  children: ReactNode
  weight?: 'normal' | 'medium' | 'semibold' | 'bold'
  accent?: 'primary' | 'secondary'
  className?: string
}

const Paragraph = ({
  children,
  weight = 'normal',
  accent = 'primary',
  className,
}: Props) => (
  <p
    className={classNames(
      `text-base text-primary-black font-${weight}`,
      accent === 'primary' ? 'text-primary-black' : 'text-secondary-gray',
      className
    )}
  >
    {children}
  </p>
)

export default Paragraph
